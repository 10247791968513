<template>
  <v-icon @click="$emit('onClick')" :disabled="disable">mdi-thumb-down-outline</v-icon>
</template>

<script>
export default {
  name: 'OrganizzeUnlikeButton',
  props: {
    disable: {
      type: Boolean,
      default: false
    }
  },
  directives: {},
  components: {},
  data: () => ({}),
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-icon { color: $colorError; }
</style>